import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["openButton", "closeButton", "dialog"]

  connect() {
    this.openButtonTarget.setAttribute("aria-haspopup", "true");
    this.openButtonTarget.setAttribute("aria-expanded", "false");
    this.openButtonTarget.setAttribute("aria-controls", this.dialogTarget.id);
    this.openButtonTarget.addEventListener("click", this.open.bind(this));
    this.closeButtonTarget.addEventListener("click", this.close.bind(this));
    this.dialogTarget.addEventListener("click", this.checkIfClose.bind(this))
  }

  open() {
    this.openButtonTarget.setAttribute("aria-expanded", "true");
    this.dialogTarget.showModal()
  }

  checkIfClose(e: Event) {
    const targetElement = e.target as HTMLElement
    if( targetElement === this.closeButtonTarget || targetElement.getAttribute("data-close-on-click") === "true") {
      this.dialogTarget.close()
    }
  }

  close(e: Event) {
    this.openButtonTarget.setAttribute("aria-expanded", "false");
    this.dialogTarget.close()
  }

  declare readonly dialogTarget: HTMLDialogElement;
  declare readonly openButtonTarget: HTMLButtonElement;
  declare readonly closeButtonTarget: HTMLButtonElement;
}
