import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    maxWidth: Number
  }

  connect() {
    this.toggleSticky();
    window.addEventListener("resize", this.toggleSticky.bind(this));
  }

  toggleSticky() {
    const shouldStick = this.element.getBoundingClientRect().height <= window.innerHeight
      && (this.maxWidthValue ?? 0) < window.innerWidth;
    this.element.classList.toggle("sticky", shouldStick);
  }

  declare readonly maxWidthValue: number;
}
