import { Controller } from   "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menuButton", "closeButton", "header", "menu", "menuText", "blackOverlay"]

  menuIsOpen = false;
  headerIsTransparent = true

  connect() {
    this.blackOverlayTarget.addEventListener("click", this.hideMenu.bind(this));
    this.menuButtonTarget.addEventListener("click", this.toggleMenu.bind(this));
    this.closeButtonTarget.addEventListener("click", this.toggleMenu.bind(this));
    this.headerIsTransparent = this.headerTarget.classList.contains("cp-header--transparent");
  }

  toggleMenu() {
    if (this.menuIsOpen) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  showMenu() {
    this.menuIsOpen = true;

    this.headerTarget.classList.add("cp-header--blackContent", "cp-header--transparent");
    this.menuTarget.classList.add("cp-menu--show");
    this.menuTextTarget.innerHTML = "Lukk";
    this.menuButtonTarget.setAttribute("aria-expanded", "true");
  }

  hideMenu() {
    this.menuIsOpen = false;

    this.headerTarget.classList.remove("cp-header--blackContent", "cp-header--transparent");
    if (!this.headerIsTransparent) {
      this.headerTarget.classList.remove("cp-header--transparent");
    }
    this.menuTarget.classList.remove("cp-menu--show");
    this.menuTextTarget.innerHTML = "Meny";
    this.menuButtonTarget.setAttribute("aria-expanded", "false");
  }


  declare readonly blackOverlayTarget: HTMLElement;
  declare readonly menuButtonTarget: HTMLElement;
  declare readonly closeButtonTarget: HTMLElement;
  declare readonly headerTarget: HTMLElement;
  declare readonly menuTarget: HTMLElement;
  declare readonly menuTextTarget: HTMLElement;
}
