import { Controller } from  "@hotwired/stimulus";

declare const Turbo: typeof import("@hotwired/turbo");

export default class extends Controller {
  static values = {
    frame: String
  }

  static targets = ["inputField"]

  connect() {
    this.inputFieldTargets.forEach(inputField => inputField.addEventListener("change", this.refreshFrame.bind(this)))
  }

  refreshFrame() {

    const url = this.inputFieldTargets
      .filter(inputField => inputField.value)
      .reduce((url, inputField) => {
        url.searchParams.set(inputField.name, inputField.value);
        return url;
      }, new URL(window.location.href))

    Turbo.visit(url, { frame: this.frameValue })
  }

  declare readonly inputFieldTargets: HTMLInputElement[];
  declare readonly frameValue: string;
}
