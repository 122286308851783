import { Application } from "@hotwired/stimulus";
import AccordionController from "./controllers/accordion_controller";
import DialogController from "./controllers/dialog_controller";
import RefreshOnSelectorController from "./controllers/refresh-on-select_controller";
import ChartController from "./controllers/chart_controller";
import FilterController from "./controllers/filter_controller";
import StickyController from "./controllers/sticky_controller";
import TabsController from "./controllers/tabs_controller";
import TopHeadingController from "./controllers/top-heading_controller";
import SidebarNavController from "./controllers/sidebar-nav_controller";
import MenuController from "./controllers/menu_controller";

declare global {
  interface Window {
        Stimulus: Application;
    }
    interface Document {
        startViewTransition?: (callback: () => unknown) => void;
    }
}

window.Stimulus = Application.start();
window.Stimulus.register("accordion", AccordionController);
window.Stimulus.register("dialog", DialogController);
window.Stimulus.register("refresh-on-select", RefreshOnSelectorController);
window.Stimulus.register("chart", ChartController);
window.Stimulus.register("filter", FilterController);
window.Stimulus.register("sticky", StickyController);
window.Stimulus.register("sidebar-nav", SidebarNavController);
window.Stimulus.register("top-heading", TopHeadingController);
window.Stimulus.register("tabs", TabsController);
window.Stimulus.register("menu", MenuController);
