import {Controller} from "@hotwired/stimulus";
import type  { SidebarNavController } from "./sidebar-nav_controller";

export default class extends Controller {
  static targets = ["heading"];
  static outlets = ["sidebar-nav"];
  TOP_MARGIN_OFFSET = 30;

  connect() {
    const intersectionObserver = new IntersectionObserver(entries => {
      const topEl = this.findTopHeading();

      if (topEl?.id) {
        this.sidebarNavOutlet.setTopHeading(topEl?.id)
      }
    }, {
      rootMargin: `-${this.TOP_MARGIN_OFFSET}px`,
      threshold: [0, 0.1, 0.9, 1]
    });

    this.headingTargets.forEach((heading) => {
      intersectionObserver.observe(heading);
    })
  }

  findTopHeading() {
    return this.headingTargets.reduce<HTMLHeadingElement | undefined>((top, el) => {
      const isAboveViewPortTop = (el.getBoundingClientRect().top - this.TOP_MARGIN_OFFSET) < 0;
      const isClosestToViewPortTop = el.getBoundingClientRect().top > (top?.getBoundingClientRect().top ?? -Infinity);

      return isAboveViewPortTop && isClosestToViewPortTop ? el : top;
    }, undefined);
  }

  declare readonly headingTargets: HTMLHeadingElement[];
  declare readonly sidebarNavOutlet: SidebarNavController;
}
