import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tablist", "tab", "panel"];
  static classes = ["tablist", "tab", "panel"]

  connect() {
    this.tablistTarget.setAttribute("role", "tablist");
    this.tablistTarget.setAttribute("aria-orientation", "horizontal");
    this.tablistTarget.classList.add(...this.tablistClasses);

    this.tabTargets.forEach((tab, index) => {
      tab.setAttribute("role", "tab");
      tab.setAttribute("aria-selected", "false");
      tab.removeAttribute("tabindex");
      tab.classList.add(...this.tabClasses);
      if (this.panelTargets[index].id) {
        tab.setAttribute("aria-controls", this.panelTargets[index].id);
      }
    });

    this.panelTargets.forEach((panel, index) => {
      panel.setAttribute("role", "tabpanel");
      panel.setAttribute("tabindex", "0");
      panel.classList.add("hidden");
      panel.classList.add(...this.panelClasses);
      if(this.tabTargets[index].id) {
        panel.setAttribute("aria-labelledby", this.tabTargets[index].id);
      }
    });

    // Select the first tab
    this.tabTargets[0].setAttribute("aria-selected", "true");
    this.tabTargets[0].setAttribute("tabindex", "0");
    this.panelTargets[0].classList.remove("hidden");
  }

  prev() {
    const currentIndex = this.tabTargets.findIndex((tab) => tab.getAttribute("aria-selected") === "true");
    const prevIndex = mod(currentIndex - 1, this.tabTargets.length)
    this.selectTab(this.tabTargets[prevIndex]);
  }

  next() {
    const currentIndex = this.tabTargets.findIndex((tab) => tab.getAttribute("aria-selected") === "true");
    const nextIndex = mod(currentIndex + 1, this.tabTargets.length)
    this.selectTab(this.tabTargets[nextIndex]);

  }

  select(event: CustomEvent) {
    event.preventDefault();

    if (event.target instanceof HTMLElement) {
      this.selectTab(event.target);
    }
  }

  selectTab(tab: HTMLElement) {
    const panel = this.panelTargets.find((panel) => panel.id === tab.getAttribute("aria-controls"));

    this.tabTargets.forEach((tab) => {
      tab.setAttribute("aria-selected", "false");
      tab.removeAttribute("tabindex");
    });

    this.panelTargets.forEach((panel) => {
      panel.classList.add("hidden");
      panel.setAttribute("tabindex", "0");
    });

    tab.setAttribute("aria-selected", "true");
    tab.setAttribute("tabindex", "0");
    tab.focus();
    panel?.classList.remove("hidden");
  }

  declare readonly tablistTarget: HTMLElement;
  declare readonly tabTargets: HTMLElement[];
  declare readonly panelTargets: HTMLElement[];
  declare readonly tablistClasses: string[];
  declare readonly tabClasses: string[];
  declare readonly panelClasses: string[];
}

function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

