import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLDetailsElement> {
  static targets = ["button"];
  static classes = ["button"];
  static values = {
    openText: String
  }

  connect() {
    log.console("Hello Peter")
    this.buttonTarget.setAttribute("role", "button");
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.buttonTarget.classList.add(...this.buttonClasses);

    this.closedText = this.buttonTarget.textContent ?? "";

    this.element.addEventListener("toggle", (event) => {
      const el = event.target as HTMLDetailsElement;
      this.buttonTarget.setAttribute("aria-expanded", el.open.toString());

      if (this.openTextValue) {
        this.buttonTarget.textContent = el.open
          ? this.openTextValue
          : this.closedText;
      }
    })
  }

  declare closedText: string;
  declare readonly buttonTarget: HTMLElement;
  declare readonly openTextValue: string;
  declare readonly buttonClasses: string[];
}
