import { Controller } from "@hotwired/stimulus";

type ParentListRecord = Record<string, HTMLElement[]>;

export default class extends Controller implements SidebarNavController{
  static targets = ["item"];
  closestParent: ParentListRecord = {};

  connect() {
    this.closestParent = this.itemTargets.reduce<ParentListRecord>((rec, el, index, arr) => {
      const parentEl = arr.slice(0, index)
        // @ts-ignore
        .findLast((parentEl) => parentEl.dataset.level < el.dataset.level);

      const href = el.getAttribute("href");

      if (href) {
        rec[href] = parentEl ? [parentEl].concat(rec[parentEl.getAttribute("href")]) : [];
      }

      return rec;
    }, {});
  }

  setTopHeading(headingId: string): void {
    this.itemTargets.forEach((el) => {
      const href = el.getAttribute("href");
      const isCurrent = href === `#${headingId}`
      el.setAttribute("aria-current", isCurrent.toString());
      el.classList.remove("in-path");

      if (isCurrent && this.closestParent[href]) {
         this.closestParent[href].forEach((parentEl) => {
           parentEl.classList.add("in-path");
         });
      }
    });
  }

  declare readonly itemTargets: HTMLElement[];
}

export interface SidebarNavController {
  setTopHeading(headingId: string): void;
}
