import { Controller } from   "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["query", "elements"]

  connect(){
    this.queryTarget.addEventListener('input', () => {

      if(this.queryTarget.value === '') {
        this.showAll()
      } else {
        this.showMatches(this.queryTarget.value.toLowerCase())
      }
    })
  }

  showAll(){
    this.elementsTargets.forEach((element) => {
      element.style.display = ''
    })
  }

  showMatches(queryString: string){
    this.elementsTargets.forEach((element) => {
      if(element.innerText.toLowerCase().indexOf(queryString) === -1){
        element.style.display = 'none'
      }
    })
  }

  declare readonly queryTarget: HTMLInputElement;
  declare readonly elementsTargets: HTMLElement[];
}
